import React, { useContext } from "react"

import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { useDistributionContentQuery } from "../../hooks/distribution/useDistributionContentQuery"
import { translateContent } from "../../utils/translate-content"

export const Distribution = ({ isHome }) => {
  const { language } = useContext(GlobalStateContext)
  const {
    wpPage: {
      distributionCustomFields: {
        distributionSectionTitlePl,
        distributionSectionTitle,
        distributionSectionTextPl,
        distributionSectionText,
        distributionSectionTelephoneValue,
        distributionSectionTelephoneKeyPl,
        distributionSectionTelephoneKey,
        distributionSectionEmailValue,
        distributionSectionEmailValuePl,
        distributionSectionEmailKey,
        distributionSectionContactValue,
        distributionSectionContactKeyPl,
        distributionSectionContactKey
      }
    }
  } = useDistributionContentQuery()

  return (
    <div className={isHome ? "content-wrapper distribution distribution-home" : "content-wrapper distribution distribution-page"}>
      <h2 className="content-wrapper__header">{translateContent(language, distributionSectionTitle, distributionSectionTitlePl)}</h2>
      <div className="distribution__container">
        <p>{translateContent(language, distributionSectionText, distributionSectionTextPl)}</p>
        <div className="distribution-contact">
          <div className="distribution-contact__row">
            <p>{translateContent(language, distributionSectionContactKey, distributionSectionContactKeyPl)}:</p>
            <p> {distributionSectionContactValue}</p>
          </div>

          <div className="distribution-contact__row">
            <p>{translateContent(language, distributionSectionTelephoneKey, distributionSectionTelephoneKeyPl)}:</p>
            <p> {distributionSectionTelephoneValue}</p>
          </div>

          <div className="distribution-contact__row">
            <p>{distributionSectionEmailKey}:</p>
            <p>{translateContent(language, distributionSectionEmailValue, distributionSectionEmailValuePl)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
