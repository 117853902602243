import { graphql, useStaticQuery } from "gatsby"

export const useHomeQuery = () => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      wpPage(id: { eq: "cG9zdDozMg==" }) {
        homePageCustomFields {
          homePageTitleSection1
          homePageTitleSection1Pl
          homePageLogo {
            link
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            id
          }
        }
      }
    }
  `)

  return data
}
