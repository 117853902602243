import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

export const HomeLogo = ({ logo }) => {
  const imageData = getImage(logo)

  return (
    <div className="content-wrapper home-logo">
      <GatsbyImage image={imageData} alt="Moon Lark" className="home-logo__image" />
    </div>
  )
}
