import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Slider from "react-slick"

import { useHomeLatestProductsQuery } from "../../hooks/home/useHomeLatestProductsQuery"
import { useWindowSize } from "../../hooks/window-size/useWindowSize"

export const Carousel = () => {
  const {
    allWpProduct: { nodes: latestProducts }
  } = useHomeLatestProductsQuery()
  const [size] = useWindowSize()

  const setResponsiveSlides = () => {
    if (size[1] > 850) return 4
    else if (size[1] <= 600) return 1
    else return 3
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: setResponsiveSlides(),
    slidesToScroll: setResponsiveSlides(),
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    dotsClass: "slick-dots slick-thumb"
  }
  return (
    <Slider {...settings}>
      {latestProducts.map((item) => {
        const {
          id,
          slug,
          product: { productType, productTitle, productStyle }
        } = item
        const imageData = getImage(item.product.productImage.localFile)
        return (
          <Link key={id} to={`/${productType}/${slug}`}>
            <div className="latest-product">
              <GatsbyImage image={imageData} className="latest-product__image" alt={slug} />
              <h3 className="latest-product__header">{productTitle}</h3>
              <p className="latest-product__subheader">{productStyle}</p>
            </div>
          </Link>
        )
      })}
    </Slider>
  )
}
