import { graphql, useStaticQuery } from "gatsby"

export const useHomeLatestProductsQuery = () => {
  const data = useStaticQuery(graphql`
    query HomeLatestProductsQuery {
      allWpProduct(limit: 12, sort: { order: DESC, fields: date }) {
        nodes {
          id
          slug
          product {
            fieldGroupName
            productAlcohol
            productDescription
            productEanCode
            productExtract
            productIngredients
            productTitle
            productStyle
            productType
            productImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              link
            }
          }
        }
      }
    }
  `)

  return data
}
