import { graphql, useStaticQuery } from "gatsby";

export const useDistributionContentQuery = () => {
  const data = useStaticQuery(graphql`
    query DistributionContentQuery {
      wpPage(id: { eq: "cG9zdDoyMg==" }) {
        distributionCustomFields {
          distributionSectionTitlePl
          distributionSectionTitle
          distributionSectionTextPl
          distributionSectionText
          distributionSectionTelephoneValue
          distributionSectionTelephoneKeyPl
          distributionSectionTelephoneKey
          distributionSectionEmailValue
          distributionSectionEmailValuePl
          distributionSectionEmailKey
          distributionSectionContactValue
          distributionSectionContactKeyPl
          distributionSectionContactKey
        }
      }
    }
  `);

  return data;
};
