import React, { useContext } from "react"

import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { translateContent } from "../../utils/translate-content"
import { Carousel } from "../Carousel/Carousel"

export const HomeLatestProducts = ({ sectionTitle, sectionTitlePl }) => {
  const { language } = useContext(GlobalStateContext)

  return (
    <div className="content-wrapper latest-products">
      <h2 className="content-wrapper__header">{translateContent(language, sectionTitle, sectionTitlePl)}</h2>
      <div className="latest-products__container">
        <Carousel />
      </div>
    </div>
  )
}
