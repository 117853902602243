import * as React from "react"

import { BackgroundLayout } from "../components/BackgroundLayout/BackgroundLayout"
import { Distribution } from "../components/Distribution/Distribution"
import { Footer } from "../components/Footer/Footer"
import { HomeLatestProducts } from "../components/HomeLatestProducts/HomeLatestProducts"
import { HomeLogo } from "../components/HomeLogo/HomeLogo"
import { Layout } from "../components/Layout/Layout"
import { SEO } from "../components/SEO/Seo"
import { useHomeQuery } from "../hooks/home/useHomeQuery"

import "../sass/main.scss"

// markup
const IndexPage = () => {
  const {
    wpPage: {
      homePageCustomFields: { homePageLogo: data, homePageTitleSection1, homePageTitleSection1Pl }
    }
  } = useHomeQuery()

  return (
    <main>
      <Layout>
        <BackgroundLayout>
          <HomeLogo logo={data.localFile} />
          <HomeLatestProducts sectionTitle={homePageTitleSection1} sectionTitlePl={homePageTitleSection1Pl} />
          <Distribution isHome={true} />
          <Footer />
        </BackgroundLayout>
      </Layout>
    </main>
  )
}

export default IndexPage

export const Head = () => <SEO title="Home" />
